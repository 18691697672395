module.exports = {
    brandInfo: {
        phoneNumber: "15878870645",
        contactEmail: "info@canadianauto.news",
        address: `4915 54 Street Red Deer AB`,
        copyright: `© ${new Date().getFullYear()} American Auto News`,
        defaultCampaignId: "174",
    },

    images: {
        icon: "images/logo.png",
    },
    project: "aan-credit-application",
    name: "American Auto News",
    description: "Get the best rates ever",

    siteUrl: "https://www.americanauto.news/",
    homeUrl: "/",
    termsUrl: "/terms_of_use",
    privacyPolicyUrl: "/privacy_policy",
    successUrl: "/success",

    hideSuccessCtas: true,
};